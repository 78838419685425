<template>
  <div>
    <v-card class="ma-1 pa-1">
      <v-card-title class="pa-1">
        <span>{{ $t("joint_venture.index") }}</span>
        <v-spacer></v-spacer>

        <v-btn
          v-if="$store.getters.checkPermission('joint_venture-create')"
          color="#6ac82d"
          x-small
          dark
          fab
          @click="newItem"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        dense
        fixed-header
        :loading-text="$t('loadingText')"
        :no-data-text="$t('noDataText')"
        :height="screenHeight"
        :loading="loading"
        :headers="headers"
        :items="items"
        class="ma-1"
        style="border: 1px solid #aaa"
        item-key="id"
        :server-items-length="server_items_length"
        :options.sync="dataTableOptions"
        :disable-pagination="true"
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100],
          itemsPerPageAllText: $t('itemsPerPageAllText'),
          itemsPerPageText: $t('itemsPerPageText'),
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
        }"
        @update:page="updatePage"
        @update:items-per-page="updatePerPage"
      >
        <template v-slot:item.id="{ item }">{{
          items
            .map(function (x) {
              return x.id;
            })
            .indexOf(item.id) + from
        }}</template>
        <template v-slot:item.manager_fio="{ item }">
          {{
            item.employee["firstname_" + $i18n.locale] +
            " " +
            item.employee["lastname_" + $i18n.locale] +
            " " +
            item.employee["middlename_" + $i18n.locale]
          }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="$store.getters.checkPermission('joint_venture-update')"
            color="blue"
            small
            icon
            @click="editItem(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.getters.checkPermission('joint_venture-delete')"
            color="red"
            small
            icon
            @click="deleteItem(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="editDialog"
      @keydown.esc="editDialog = false"
      persistent
      max-width="900"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <span class="headline">{{ textDialogHeader }}</span>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            x-small
            fab
            class
            @click="editDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form @keyup.native.enter="save" ref="dialogForm">
          <v-card-text class="py-0">
            <v-row>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-text-field
                  :label="$t('name_uz_latin')"
                  v-model="form.department.name_uz_latin"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-text-field
                  :label="$t('name_uz_cyril')"
                  v-model="form.department.name_uz_cyril"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-text-field
                  :label="$t('name_ru')"
                  v-model="form.department.name_ru"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-text-field
                  :label="$t('department.department_code')"
                  v-model="form.department.department_code"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-autocomplete
                  :label="$t('department.department_type_id')"
                  class="pa-0"
                  clearable
                  v-model="form.department.department_type_id"
                  :items="departmentTypes"
                  item-value="id"
                  :item-text="'name_' + $i18n.locale"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details
                  dense
                  outlined
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-autocomplete
                  :label="$t('countries.index')"
                  class="pa-0"
                  clearable
                  v-model="form.department.country_id"
                  :items="countries"
                  item-value="id"
                  :item-text="'name_' + $i18n.locale"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details
                  dense
                  outlined
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-autocomplete
                  :label="$t('regions.index')"
                  class="pa-0"
                  clearable
                  v-model="form.department.region_id"
                  :items="
                    countries.find((v) => {
                      if (v.id == form.department.country_id) return v;
                    })
                      ? countries.find((v) => {
                          if (v.id == form.department.country_id) return v;
                        }).regions
                      : []
                  "
                  item-value="id"
                  :item-text="'name_' + $i18n.locale"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details
                  dense
                  outlined
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-system-bar class="pa-1 justify-center" color="grey lighten-4">
            {{ $t("joint_venture.manager") }}
          </v-system-bar>
          <v-card-text class="py-0">
            <v-row>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-text-field
                  :label="$t('employee.firstname_uz_latin')"
                  v-model="form.employee.firstname_uz_latin"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-text-field
                  :label="$t('employee.firstname_uz_cyril')"
                  v-model="form.employee.firstname_uz_cyril"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-text-field
                  :label="$t('employee.lastname_uz_latin')"
                  v-model="form.employee.lastname_uz_latin"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-text-field
                  :label="$t('employee.lastname_uz_cyril')"
                  v-model="form.employee.lastname_uz_cyril"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-text-field
                  :label="$t('employee.middlename_uz_latin')"
                  v-model="form.employee.middlename_uz_latin"
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-text-field
                  :label="$t('employee.middlename_uz_cyril')"
                  v-model="form.employee.middlename_uz_cyril"
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-text-field
                  :label="$t('employee.tabel')"
                  v-model="form.employee.tabel"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details
                  type="email"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-text-field
                  :label="$t('user.email')"
                  v-model="form.employee.user.email"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details
                  type="email"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="px-1">
                <v-text-field
                  :label="$t('user.phone')"
                  v-model="form.employee.phone_number"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <small color="red">{{ $t("input_required") }}</small>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("loadingText") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const axios = require("axios").default;
import Swal from "sweetalert2";
export default {
  data() {
    return {
      loading: false,
      editDialog: false,
      items: [],
      form: {
        department: {
          id: Date.now(),
        },
        employee: {
          id: Date.now(),
          user: { id: Date.now() },
        },
      },
      headers: [
        { text: "#", value: "id", align: "center", width: 30, sortable: false },
        {
          text: this.$t("joint_venture.name"),
          value: "department.name_" + this.$i18n.locale,
          sortable: false,
        },
        {
          text: this.$t("joint_venture.manager_fio"),
          value: "manager_fio",
          sortable: false,
        },
        {
          text: this.$t("employee.tabel"),
          value: "employee.tabel",
          sortable: false,
        },
        {
          text: this.$t("employee.phone"),
          value: "employee.phone_number",
          sortable: false,
        },
        {
          text: this.$t("user.username"),
          value: "employee.user.username",
          sortable: false,
        },
        {
          text: this.$t("user.email"),
          value: "employee.user.email",
          sortable: false,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          width: 50,
          align: "center",
          sortable: false,
        },
      ],
      page: 1,
      from: 1,
      server_items_length: -1,
      dataTableOptions: { page: 1, itemsPerPage: 20 },
      departmentTypes: [],
      textDialogHeader: "",
      countries: [],
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 170;
    },
  },
  methods: {
    updatePage($event) {
      this.getList();
    },
    updatePerPage($event) {
      this.getList();
    },
    getList() {
      this.loading = true;
      axios
        .post(this.$store.state.backend_url + "api/joint-venture/list", {
          pagination: this.dataTableOptions,
        })
        .then((res) => {
          this.items = res.data.data;
          this.items.map((v) => {
            v.employee = v.employees[0];
            v.employee.phone_number =
              v.employee.employee_phones[0].phone_number;
            v.department.country_id = v.department.joint_company.country_id;
            v.department.region_id = v.department.joint_company.region_id;
          });
          this.from = res.data.from;
          this.server_items_length = res.data.total;
          this.loading = false;
          //   console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    departmentTypeJointVenture() {
      axios
        .get(
          this.$store.state.backend_url +
            "api/department-type-joint-venture/list"
        )
        .then((res) => {
          this.departmentTypes = res.data.department_type;
          this.countries = res.data.countries;
          //   console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    newItem() {
      this.editDialog = true;
      this.textDialogHeader = this.$t("joint_venture.create");
      this.form = {
        department: {
          id: Date.now(),
        },
        employee: {
          id: Date.now(),
          user: { id: Date.now() },
        },
      };
    },
    editItem(item){
      this.form = item;
      this.editDialog = true;
      this.textDialogHeader = this.$t("joint_venture.edit");
      console.log(item);
    },
    save() {
      if (this.$refs.dialogForm.validate()) {
        this.loading = true;
        this.editDialog = false;
        axios
          .post(
            this.$store.state.backend_url + "api/joint-venture/update",
            this.form
          )
          .then((res) => {
            // this.departmentTypes = res.data;
            // console.log(res);
            this.$refs.dialogForm.reset();
            this.getList();
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
    validatUsername(text, text1, text2) {
      alert(text1.substr(0, 1));
      this.form.employee.user.username =
        text.substr(0, 1) + text1.substr(0, 1) + text2;
    },
  },
  mounted() {
    this.getList();
    this.departmentTypeJointVenture();
  },
};
</script>